// {{< tabs >}}
.book-tabs {
  margin-top: $padding-16;
  margin-bottom: $padding-16;
  border: $padding-1 solid #e9ecef;
  border-radius: $border-radius;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  label {
    display: inline-block;
    padding: $padding-8 $padding-16;
    border-bottom: $padding-1 transparent;
    cursor: pointer;
  }
  .book-tabs-content {
    order: 999; //Move content blocks to the end
    width: 100%;
    border-top: $padding-1 solid #f8f9fa;
    padding: $padding-16;
    display: none;
  }
  input[type="radio"]:checked + label {
    border-bottom: $padding-4 solid #c52455;
  }
  input[type="radio"]:checked + label + .book-tabs-content {
    display: block;
  }
  input[type="radio"]:focus + label {
    outline {
      outline-style: auto;
      outline-color: currentColor;
      outline-color: -webkit-focus-ring-color;
    };
  }
  input.toggle {
    height: 0;
    width: 0;
    overflow: hidden;
    opacity: 0;
    position: absolute;
  }
}

