// ==============================
// Custom style
// 自定义样式
// ==============================


// Used in layout
$padding-1: 1px !default;
$padding-4: 0.25rem !default;
$padding-8: 0.5rem !default;
$padding-12: 0.75rem !default;
$padding-16: 1rem !default;

$font-size-base: 16px !default;
$font-size-12: 0.75rem !default;
$font-size-14: 0.875rem !default;
$font-size-16: 1rem !default;

$body-min-width: 20rem !default;
$container-max-width: 80rem !default;
$menu-width: 13rem !default;
$toc-width: 16rem !default;
$mobile-breakpoint: $menu-width + $body-min-width * 1.2 + $toc-width !default;


@mixin fixed {
  position: fixed;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
@mixin absolute {
  position: absolute;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
@mixin outline {
  outline-style: auto;
  outline-color: currentColor;
  outline-color: -webkit-focus-ring-color;
}

aside nav ul {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    margin: 0.3em 0;
    position: relative;
  }

  a {
    display: block;
  }

  a:hover {
    opacity: 0.5;
  }

  ul {
    padding-inline-start: $padding-16;
  }
}

.book-columns {
  flex-wrap: wrap;
}
.flex {
  display: flex;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-even {
  flex: 1 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.header-title a img {
  vertical-align: middle;
}
[theme=dark] .book-menu .book-menu-content {
  background-color: #292a2d !important;
  z-index: 50;
}
.book-menu {
  flex: 0 0 $menu-width;
  font-size: $font-size-14;
  font-family: roboto,sans-serif;

  .book-menu-content {
    width: $menu-width;
    padding: $padding-16;
    z-index: 50;

    @include fixed;
    background-color: #fff;
    top: $header-height;
  }

  a,
  label {
    color: inherit;
    cursor: pointer;
    word-wrap: break-word;
  }

  a.active {
    color: #2d96bd;
  }

  input.toggle + label + ul {
    display: none;
  }

  input.toggle:checked + label + ul {
    display: block;
  }

  input.toggle + label::after {
    content: "▸";
  }

  input.toggle:checked + label::after {
    content: "▾";
  }
}

input.toggle {
  height: 0;
  width: 0;
  overflow: hidden;
  opacity: 0;
  position: absolute;
}

// for RTL support
body[dir="rtl"] .book-menu {
  input.toggle + label::after {
    content: "◂";
  }

  input.toggle:checked + label::after {
    content: "▾";
  }
  .book-header {
    display: none;
    margin-bottom: $padding-16;

    label {
      line-height: 0;
    }

    img.book-icon {
      height: 1.5em;
      width: 1.5em;
    }
  }
}


// Responsive styles
.book-menu-content,
.book-header aside {
  transition: 0.2s ease-in-out;
  transition-property: transform, margin, opacity, visibility;
  will-change: transform, margin, opacity;
}

@media screen and (max-width: 960px) {
  #menu-control{
    display: inline;
  }

  .book-menu {
    visibility: hidden;
    margin-inline-start: -$menu-width;
    font-size: $font-size-base;
    z-index: 1;
  }

  #menu-control:focus ~ main label[for="menu-control"] {
    @include outline;
  }
  #own-checkbox:checked ~ main {
    .book-menu {
      visibility: initial;
    }
    .book-menu .book-menu-content {
      transform: translateX($menu-width);
      box-shadow: 0 0 $padding-8 rgba(0, 0, 0, 0.1);
      background-color: #fff;
      z-index: 50;
    }
    .book-page {
      opacity: 0.25;
    }
    .book-menu-overlay {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 49;
    }
  }
  #menu-control:checked ~ main {
    .book-menu {
      visibility: initial;
    }

    .book-menu .book-menu-content {
      transform: translateX($menu-width);
      box-shadow: 0 0 $padding-8 rgba(0, 0, 0, 0.1);
    }

    .book-page {
      opacity: 0.25;
    }

    .book-menu-overlay {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  // for RTL support
  body[dir="rtl"] #menu-control:checked ~ main {
    .book-menu .book-menu-content {
      transform: translateX(-$menu-width);
    }
  }
}

// 自定义样式
.baseof-div {
  position: relative;
  margin-top: 4rem;
  .book-menu {
    position: sticky;
    top: 1rem;
    align-self: flex-start;
    flex-shrink: 0;
    position: -webkit-sticky;
    height: calc(100vh);
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(1, 87, 155);
  border-color: rgb(1, 87, 155);
  .book-menu {
    flex: 0 0 6rem;
    position: relative;
    // width: 4rem;
    height: 4rem;
    input.toggle + label::after {
      content: "◂";
      color: #fff;
    }

    input.toggle:checked + label::after {
      content: "▾";
      color: #fff;
    }
  }
}

.book-page {
  min-width: $body-min-width;
  flex-grow: 1;
  padding: $padding-16;
  min-height: calc(100vh - 6rem);
}

.book-section-flat {
  margin: $padding-16 * 2 0;

  > a,
  > span,
  > label {
    font-weight: bolder;
  }

  > ul {
    padding-inline-start: 0;
  }
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.book-page {
  min-width: $body-min-width;
  flex-grow: 1;
  padding: $padding-16;
  min-height: calc(100vh - 6rem);
}
.wrapper {
  position: relative;
}
.wrapper main .container {
  padding: 0 1rem;
}
.wrapper main .home-container {
  padding: 0;
}


///////////////////////
// home page
///////////////////////

.index-home {
  max-width: 100%;
  width: 100%;
  line-height: 1.2;
}
.index-page {
  text-align: center;
}
.index-row {
  // height: 18.75rem;
  color: $global-background-color;
  // padding: $padding-16 $padding-16 * 8;
  box-sizing: border-box;
}
.index-row.index-row-1 {
  background: url('/images/back-ground.jpg');
  background-repeat: no-repeat;
  background-size: auto 100%;
  padding: 1rem;
}
.index-row.index-row-2 {
  background: url('/images/back-ground-mesh-blue.jpg');
  background-repeat: repeat;
  background-size: auto 100%;
  padding: 1rem;
  img {
    width: 95%; 
    margin: 0 auto; 
    margin-bottom: 1rem;
  }
}
.index-row.index-row-3 {
  background: url('/images/back-ground-polygonal.jpg');
  background-repeat: repeat;
  background-size: center;
  padding: 1rem;
  img {
    // height: 100%;
    width: 60%;
    margin: 0 auto;
  }
  .text-wrap {
    padding: $padding-12;
    .text-title {
      font-size: 1.5rem;
      padding-bottom: $padding-12;
      margin-bottom: 1.5rem;
      color: #000000;
    }
    .text-content {
      font-size: 1rem;
      text-align: left;
      line-height: 1.5rem;
      margin-bottom: 1.5rem;
      color: #333333;
    }
  }
}
.video-wrap {
  width: 90vw;
  height: 67.5vw;
}
.index-row-4 {
  background: url('/images/back-ground-blue-repeat.jpg');
  background-repeat: repeat;
  background-size: center;
  padding: 1rem;
  img {
    width: 90%;
    // height: 100%;
  }
  & .right-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .right-item {
      width: 48%;
      // height: 50%;
      padding: $padding-8;
      border: 1px solid #fff;
      border-radius: 8px;
      margin-top: 1rem;
      box-sizing: border-box;
      .my-icon {
        width: 2.25rem;
        height: 2.25rem;
        font-size: 2.25rem;
        margin-bottom: .8rem;
      }
      .item-desc {
        .desc-title {
          font-weight: bold;
          font-size: 1.125rem;
          padding: $padding-12;
        }
        .desc-text {
          font-weight: .875rem;
          line-height: 1.75rem;
          padding-left: $padding-8;
          padding-right: $padding-8;
      }
      }
    }
  }
}
.index-row-5 {
  background: url('/images/back-ground-grey-repeat.jpg');
  background-repeat: repeat;
  color: $global-background-color-dark;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: left;
  height: auto;
  padding: 1rem 0;
  .feature-item {
    width: 90%;
    background-color: #fff;
    display: flex;
    border-radius: $padding-8;
    // display: flex;
    margin: 0 auto;
    margin-bottom: $padding-16;
    .icon-wrap {
      width: 4rem;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .my-icon {
      display: inline-block;
      width: 2.5rem;
      height: 2.5rem;
      font-size: 2.5rem;
      margin: $padding-8;
      line-height: 1.2;
    }
    .feature-right {
      padding: $padding-8;
      .tips {
        color: #474747;
        font-weight: 500;
        font-size: 1.25rem;
        margin: $padding-8;
      }
      .answer {
        color: #777777;
        font-size: 1rem;
        margin: $padding-4;
      }
    }
  }
}
[theme=dark] .index-row {
  // color: $global-background-color-dark;
}
.index-logo {
  width: 14rem;
  height: 14rem;
  margin-top: $padding-4;
}
.index-title {
  font-size: 2.5rem;
}
.index-subtitle {
  font-size: 1.25rem;
  font-weight: 400;
  color: #DDDDDD;
  margin-top: $padding-16 * 2;
  padding-bottom: $padding-16;
  margin-bottom: 2rem;
}
[theme=dark] .quick-start {
  color: $global-background-color;
}
.quick-start {
  color: $global-background-color;
  padding: $padding-8 $padding-16;
  border-radius: $padding-4;
  font-size: $font-size-16 * 1.5;
  background-color: $single-link-color-dark;
}
.quick-start:hover {
  color: $global-background-color;
  background-color: $single-link-color;
}
// middle screens
@media screen and (min-width: 680px) {
  .book-page,
  .book-menu .book-menu-content {
    padding: 0 $padding-16 $padding-16 $padding-16;
  }
  .index-row {
    // height: 18.75rem;
    color: $global-background-color;
    // padding: $padding-16 $padding-16 * 8;
    box-sizing: border-box;
  }
  .index-row.index-row-1 {
    background: url('/images/back-ground.jpg');
    background-repeat: no-repeat;
    background-size: center;
    padding: 1rem;
  }
  .index-row.index-row-2 {
    background: url('/images/back-ground-mesh-blue.jpg');
    background-repeat: repeat;
    background-size: center;
    padding: 1rem;
    img {
      width: 90%; 
      margin: 0 auto; 
      margin-bottom: 1rem;
    }
  }
  .index-row.index-row-3 {
    background: url('/images/back-ground-polygonal.jpg');
    background-repeat: repeat;
    background-size: center;
    padding: 1rem;
    img {
      // height: 100%;
      width: 60%;
      margin: 0 auto;
    }
    .text-wrap {
      padding: 0 $padding-12;
      .text-title {
        font-size: 2rem;
        padding-bottom: $padding-12;
        margin-bottom: 1.5rem;
        color: #000000;
      }
      .text-content {
        font-size: 1.25rem;
        text-align: left;
        line-height: 2rem;
        margin-bottom: 1.5rem;
        color: #333333;
      }
    }
  }
  .video-wrap {
    // width: 90%;
    width: 90vw;
    height: 67.5vw;
  }
  .index-row-4 {
    background: url('/images/back-ground-blue-repeat.jpg');
    background-repeat: repeat;
    background-size: center;
    padding: 1rem;
    img {
      width: 90%;
      height: 100%;
    }
    & .right-wrap {
      display: flex;
      flex-wrap: wrap;
      .right-item {
        width: 48%;
        // height: 50%;
        padding: $padding-12;
        border: 1px solid #fff;
        border-radius: 12px;
        margin-top: 1rem;
        box-sizing: border-box;
        .my-icon {
          width: 2.25rem;
          height: 2.25rem;
          font-size: 2.25rem;
          margin-bottom: .8rem;
        }
        .item-desc {
          .desc-title {
            font-weight: bold;
            font-size: 1.125rem;
            padding: $padding-12;
          }
          .desc-text {
            font-weight: .875rem;
            line-height: 1.75rem;
            padding-left: $padding-12;
            padding-right: $padding-12;
          }
        }
      }
    }
  }
  .index-row-5 {
    display: flex;
    justify-content: space-between;
    background: url('/images/back-ground-grey-repeat.jpg');
    background-repeat: no-repeat;
    background-size: auto 100%;
    color: $global-background-color-dark;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: left;
    height: auto;
    padding: 1rem 0;
    .feature-item {
      width: 45%;
      background-color: #fff;
      border-radius: $padding-8;
      display: flex;
      margin: 0 auto;
      margin-bottom: $padding-16;
      .icon-wrap {
        width: 4rem;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .my-icon {
        display: inline-block;
        width: 2.5rem;
        height: 2.5rem;
        font-size: 2.5rem;
        margin: $padding-8;
        line-height: 1.2;
      }
      .feature-right {
        padding: $padding-8;
        .tips {
          color: #474747;
          font-weight: 500;
          font-size: 1.25rem;
          margin: $padding-8;
        }
        .answer {
          color: #777777;
          font-size: 1rem;
          margin: $padding-4;
        }
      }
    }
  }
  [theme=dark] .index-row {
    // color: $global-background-color-dark;
  }
  .index-logo {
    width: 16rem;
    height: 16rem;
    margin-top: $padding-8;
  }
  .index-title {
    font-size: 2.5rem;
  }
  .index-subtitle {
    font-size: 1.25rem;
    font-weight: 400;
    color: #DDDDDD;
    margin-top: $padding-16 * 2;
    padding-bottom: $padding-16;
    margin-bottom: 2rem;
  }
  [theme=dark] .quick-start {
    color: $global-background-color;
  }
  .quick-start {
    color: $global-background-color;
    padding: $padding-8 $padding-16;
    border-radius: $padding-4;
    font-size: $font-size-16 * 1.5;
    background-color: $single-link-color-dark;
  }
  .quick-start:hover {
    color: $global-background-color;
    background-color: $single-link-color;
  }
}

// Extra space for big screens
@media screen and (min-width: 960px) {
  .book-page,
  .book-menu .book-menu-content {
    padding: 0 $padding-16 $padding-16 $padding-16;
  }
  .index-row {
    // height: 18.75rem;
    color: $global-background-color;
    // padding: $padding-16 $padding-16 * 8;
    box-sizing: border-box;
  }
  .index-row.index-row-1 {
    background: url('/images/back-ground.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 3rem 4rem;
  }
  .index-row.index-row-2 {
    align-items: center;
    background: url('/images/back-ground-mesh-blue.jpg');
    background-repeat: repeat;
    background-size: center;
    padding: 2rem;
    img {
      width: 80%; 
      margin: 0 auto; 
      margin-bottom: 1rem;
    }
  }
  .index-row.index-row-3{
    align-items: center;
    background: url('/images/back-ground-polygonal.jpg');
    background-repeat: repeat;
    background-size: center;
    display: flex;
    justify-content: space-between;
    img {
      width: 50%;
      height: 100%;
    }
    .text-wrap {
      padding: 0 $padding-16;
      .text-title {
        font-size: 2.5rem;
        padding-bottom: $padding-16;
        margin-bottom: 2rem;
        color: #000000;
      }
      .text-content {
        font-size: 1.25rem;
        text-align: left;
        line-height: 2.25rem;
        margin-bottom: 2rem;
        color: #333333;
      }
    }
  }
  .video-wrap {
    // width: 60%;
    flex-shrink: 0;
    width: 50vw;
    height: 37.5vw;
  }
  .index-row-4 {
    background: url('/images/back-ground-blue-repeat.jpg');
    background-repeat: repeat;
    background-size: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 50%;
      height: 100%;
    }
    & .right-wrap {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1rem;
      .right-item {
        width: 48%;
        // height: 50%;
        margin-bottom: 1rem;
        padding: $padding-12;
        border: 1px solid #fff;
        border-radius: 16px;
        margin-top: 1rem;
        box-sizing: border-box;
        .my-icon {
          width: 3rem;
          height: 3rem;
          font-size: 2.25rem;
          margin-bottom: .8rem;
        }
        .item-desc {
          .desc-title {
            font-weight: bold;
            font-size: 1.5rem;
            padding: $padding-16;
          }
          .desc-text {
            font-weight: 1rem;
            line-height: 1.75rem;
            padding-left: $padding-12;
            padding-right: $padding-12;
          }

        }
      }
    }
  }
  .index-row-5 {
    background: url('/images/back-ground-grey-repeat.jpg');
    background-repeat: repeat;
    color: $global-background-color-dark;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: left;
    height: auto;
    padding-bottom: 0;
    .feature-item {
      width: 30%;
      flex-shrink: 0;
      background-color: #fff;
      border-radius: $padding-8;
      display: flex;
      margin-bottom: $padding-16;
      .icon-wrap {
        width: 4.5rem;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .my-icon {
        display: inline-block;
        width: 3rem;
        height: 3rem;
        font-size: 3rem;
        line-height: 1.2;
        margin: $padding-8;
      }
      .feature-right {
        padding: $padding-8;
        .tips {
          color: #474747;
          font-weight: 500;
          font-size: 1.25rem;
          margin: $padding-8;
        }
        .answer {
          color: #777777;
          font-size: 1rem;
          margin: $padding-4;
        }
      }
    }
  }
  [theme=dark] .index-row {
    // color: $global-background-color-dark;
  }
  .index-logo {
    width: 18rem;
    height: 18rem;
    margin-top: $padding-12;
  }
  .index-title {
    font-size: 3rem;
    margin: 1.414rem 0 .5rem;
    box-sizing: border-box;
  }
  .index-subtitle {
    font-size: 1.5rem;
    font-weight: 400;
    color: #DDDDDD;
    margin-top: $padding-16 * 2;
    padding-bottom: $padding-16;
    margin-bottom: 2rem;
  }
  [theme=dark] .quick-start {
    color: $global-background-color;
  }
  .quick-start {
    color: $global-background-color;
    padding: $padding-8 $padding-16;
    border-radius: $padding-4;
    font-size: $font-size-16 * 1.5;
    background-color: $single-link-color-dark;
  }
  .quick-start:hover {
    color: $global-background-color;
    background-color: $single-link-color;
  }
}
