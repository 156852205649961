a.book-btn {
  display: inline-block;
  font-size: $font-size-14;
  font-weight: bold;
  color: #666666;
  line-height: $padding-16 * 2;
  padding: 0 $padding-16;
  border: $padding-1 solid #666666;
  border-radius: $border-radius;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}
